import React from "react";

export default function IconMyStartups(props) {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.60751 19.0345L8.71051 13.9315L12.954 18.175L17.6895 13.4395L15 10.75H22.5V18.25L19.8105 15.5605L12.954 22.417L8.71051 18.175L4.99951 21.886C6.68463 24.4223 9.27094 26.2231 12.2344 26.9235C15.1978 27.6239 18.3166 27.1715 20.9589 25.6581C23.6012 24.1446 25.5693 21.6832 26.4644 18.7726C27.3595 15.8621 27.1146 12.7201 25.7794 9.9834C24.4441 7.24669 22.1185 5.11994 19.2736 4.03412C16.4287 2.94829 13.2774 2.98461 10.4583 4.1357C7.63918 5.2868 5.36311 7.46658 4.09131 10.2333C2.81951 13.0001 2.64709 16.1469 3.60901 19.036L3.60751 19.0345ZM1.30201 21.3715L1.28701 21.3565L1.29301 21.3505C0.437905 19.4305 -0.00268155 17.3518 1.22786e-05 15.25C1.22786e-05 6.9655 6.71551 0.25 15 0.25C23.2845 0.25 30 6.9655 30 15.25C30 23.5345 23.2845 30.25 15 30.25C8.89501 30.25 3.64501 26.605 1.30201 21.3715Z"
        fill="currentColor"
      />
    </svg>
  );
}
