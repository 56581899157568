export default function IconAttach(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.4872 2.19623C18.1505 -0.732077 12.717 -0.732077 9.38386 2.19623L0.139002 10.3035C0.0787868 10.3563 0.0469077 10.4278 0.0469077 10.5024C0.0469077 10.577 0.0787868 10.6485 0.139002 10.7014L1.44603 11.8485C1.50578 11.9007 1.58665 11.93 1.67096 11.93C1.75526 11.93 1.83614 11.9007 1.89588 11.8485L11.1407 3.74121C12.2884 2.73402 13.815 2.18069 15.4373 2.18069C17.0596 2.18069 18.5862 2.73402 19.7303 3.74121C20.8779 4.7484 21.5084 6.08821 21.5084 7.50884C21.5084 8.93258 20.8779 10.2693 19.7303 11.2765L10.3083 19.5423L8.7817 20.8821C7.35424 22.1348 5.03417 22.1348 3.60671 20.8821C2.916 20.2759 2.537 19.4708 2.537 18.6128C2.537 17.7548 2.916 16.9497 3.60671 16.3435L12.9543 8.143C13.1916 7.93783 13.5033 7.82281 13.8363 7.82281H13.8398C14.1728 7.82281 14.4809 7.93783 14.7147 8.143C14.952 8.35127 15.0795 8.62483 15.0795 8.91704C15.0795 9.20614 14.9485 9.4797 14.7147 9.68486L7.07441 16.3839C7.0142 16.4368 6.98232 16.5083 6.98232 16.5829C6.98232 16.6575 7.0142 16.729 7.07441 16.7818L8.38145 17.9289C8.44119 17.9811 8.52206 18.0104 8.60637 18.0104C8.69067 18.0104 8.77155 17.9811 8.83129 17.9289L16.468 11.2267C17.1729 10.6081 17.559 9.78745 17.559 8.91393C17.559 8.04041 17.1694 7.21663 16.468 6.60113C15.0122 5.32349 12.6461 5.3266 11.1903 6.60113L10.2835 7.40004L1.84629 14.8016C1.27364 15.3012 0.819716 15.8957 0.510834 16.5504C0.201952 17.2052 0.0442621 17.9072 0.0469077 18.6159C0.0469077 20.0552 0.688026 21.4074 1.84629 22.4239C3.04706 23.4746 4.61975 24 6.19243 24C7.76512 24 9.33781 23.4746 10.535 22.4239L21.4872 12.8183C23.0988 11.4008 23.9914 9.51389 23.9914 7.50884C23.995 5.50068 23.1024 3.61376 21.4872 2.19623Z"
        fill="currentColor"
      />
    </svg>
  );
}
